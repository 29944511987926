import firebase from 'firebase/app';
import { AirtableDataProps } from '../pages/landing/stages/FifthStage/GetEarlyAccess';

export interface IAirtableApi {
    readonly upsertEarlyAccess: (requestData: AirtableDataProps) => Promise<any>;
}

export class AirtableApiImpl implements IAirtableApi {
    constructor(private readonly functions: firebase.functions.Functions) {}

    public upsertEarlyAccess = async (requestData: AirtableDataProps): Promise<any> =>
        await this.functions.httpsCallable('upsertAirtableRecord')(requestData);
}
