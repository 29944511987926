import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoadingScreen from './components/common/LoadingScreen';
import PopupContainer from './components/common/popup/PopupContainer';
import { ThemeStore } from './libs/stores/ThemeStore';
import { routes } from './routes';
import { ThemeType } from './theme';
import { LOCAL_STORAGE_KEYS } from './utils/constants';

const LandingPage = lazy(() => import('./pages/landing/LandingPage'));

export default function App() {
    const themeStore = useContext(ThemeStore);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    useEffect(() => {
        const storedThemeType = window.localStorage.getItem(LOCAL_STORAGE_KEYS.theme) as ThemeType;
        if (storedThemeType === null) {
            themeStore.toggleTheme(
                prefersDarkMode ? ThemeType.DARK : ThemeType.LIGHT,
                prefersDarkMode
            );
        }
        // eslint-disable-next-line
    }, [prefersDarkMode]);

    return (
        <ThemeProvider theme={themeStore.theme}>
            <CssBaseline />
            <PopupContainer />
            <Router>
                <Suspense fallback={<LoadingScreen />}>
                    <Switch>
                        <Route path={routes.landing} exact component={LandingPage} />
                    </Switch>
                </Suspense>
            </Router>
        </ThemeProvider>
    );
}
