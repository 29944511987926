import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import React from 'react';

export interface LoadingButtonProps extends ButtonProps {
    readonly isLoading?: boolean;
}

export default function LoadingButton({ isLoading, ...props }: LoadingButtonProps) {
    return (
        <Button
            startIcon={isLoading ? <CircularProgress size={12} /> : props.startIcon}
            {...props}
            disabled={props.disabled || isLoading}>
            {props.children}
        </Button>
    );
}
