import { Theme, ThemeOptions } from '@material-ui/core';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles/';
import { useEffect, useRef, useState } from 'react';
import { themes, ThemeType } from '../../theme';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import { State } from '../wrappers/State';
import { createStore, createStoreContext, DefaultProviderProps } from '../wrappers/StoreWrapp';

export interface ThemeDataStoreValue {
    readonly theme: Theme;
    readonly toggleTheme: (themeType?: ThemeType, prefersDarkMode?: boolean) => void;
    readonly isDarkMode: boolean;
    readonly isLargeScreen: boolean;
}

export const ThemeStore = createStoreContext<ThemeDataStoreValue>();

export const ThemeDataStoreProvider = ({ children }: DefaultProviderProps) => {
    const isMounted = useRef(true);
    const storedThemeType = window.localStorage.getItem(LOCAL_STORAGE_KEYS.theme) as ThemeType;

    const isDarkMode = State<boolean>(storedThemeType === ThemeType.DARK, isMounted);

    const createTheme = (options: ThemeOptions): Theme => {
        return responsiveFontSizes(createMuiTheme(options), { factor: 2 });
    };

    const [theme, setTheme] = useState<Theme>(
        createTheme(themes.get(storedThemeType) || themes.get(ThemeType.LIGHT)!)
    );
    const [isLargeScreen, setLargeScreen] = useState<boolean>(
        window.innerWidth >= theme.breakpoints?.width('lg')
    );

    const handleWindowResize = () => {
        setLargeScreen(window.innerWidth >= theme.breakpoints?.width('lg'));
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
    }, []);

    const toggleTheme = (themeType?: ThemeType, prefersDarkMode?: boolean) => {
        const themeId = themeType || (isDarkMode.data ? ThemeType.LIGHT : ThemeType.DARK);
        isDarkMode.set((prev) => (prefersDarkMode !== undefined ? prefersDarkMode : !prev));
        setTheme(createTheme(themes.get(themeId)!));
        !themeType && window.localStorage.setItem(LOCAL_STORAGE_KEYS.theme, themeId);
    };

    return createStore(ThemeStore, children, {
        theme,
        toggleTheme,
        isDarkMode: isDarkMode.data,
        isLargeScreen
    });
};
