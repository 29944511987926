import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApiInjectorProvider } from './api/ApiInjector';
import App from './App';
import { ThemeDataStoreProvider } from './libs/stores/ThemeStore';

ReactDOM.render(
    <ThemeDataStoreProvider>
        <ApiInjectorProvider>
            <Router>
                <App />
            </Router>
        </ApiInjectorProvider>
    </ThemeDataStoreProvider>,
    document.getElementById('root')
);
