import { ThemeOptions } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
    interface TypeBackground {
        mainStageBg: string;
        thirdStageBg: string;
        fifthFormBg: string;
        footerBg: string;
    }
    interface TypeText {
        mainTitle: string;
        secondaryFontFamily: string;
        thirdStageTitle: string;
        fourthStageTitle: string;
    }
}

const colors = {
    lightTheme: {
        primary: '#121C43',
        secondary: '#08CDA3',
        paperPrimary: '#FFFFFF',
        backgroundDefault: '#F5F5F5',
        mainStageBg:
            'linear-gradient(160.62deg, rgba(233, 30, 99, 0.25) 0%, rgba(27, 38, 84, 0.25) 48.19%), #121C43',
        thirdStageTitle:
            'linear-gradient(121.21deg, rgba(8, 205, 163, 0.4) 17.75%, rgba(4, 109, 253, 0.5) 58.4%), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        fourthStageTitle:
            'linear-gradient(100.1deg, rgba(244, 91, 105, 0.8) 7.21%, rgba(4, 109, 253, 0.8) 78.64%), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        footerBg:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121C43',
            textPrimary: '#8b8fab',
        textSecondary: '#F2F2F2',
        textHover: '#7DF0D8',
        contrastText: '#FFFFFF',
        mainTitle: '#505A7A',
        thirdStageBg: '#121C43',
        fifthFormBg: '#09133A'
    },
    darkTheme: {}
};

const SECONDARY_FONT_FAMILIY = 'Signika Negative';

const lightTheme: ThemeOptions = {
    typography: {
        fontFamily: 'Amiko',
        h2: { fontSize: 64, fontWeight: 700, fontFamily: SECONDARY_FONT_FAMILIY },
        h1: { fontSize: 46, lineHeight: 1.2, fontWeight: 600 },
        h4: {
            fontSize: 36,
            lineHeight: 1.22,
            fontWeight: 700,
            secondaryFontFamily: SECONDARY_FONT_FAMILIY
        },
        h5: { fontSize: 24, lineHeight: 1.5, fontWeight: 400 },
        subtitle1: { fontSize: 18, letterSpacing: 0.04 },
        subtitle2: { fontSize: 14, letterSpacing: 0.04 },
        body1: { fontSize: 16, letterSpacing: 0.02, lineHeight: 1.25, fontWeight: 400 },
        caption: { fontSize: 12, fontWeight: 400, lineHeight: 1.3, letterSpacing: 0.4 }
    },
    palette: {
        type: 'light',
        common: {
            white: '#ffffff',
            black: '#000000'
        },
        primary: {
            main: colors.lightTheme.primary,
            contrastText: colors.lightTheme.contrastText
        },
        secondary: {
            main: colors.lightTheme.secondary,
            contrastText: colors.lightTheme.contrastText
        },
        background: {
            paper: colors.lightTheme.paperPrimary,
            default: colors.lightTheme.backgroundDefault,
            mainStageBg: colors.lightTheme.mainStageBg,
            thirdStageBg: colors.lightTheme.thirdStageBg,
            fifthFormBg: colors.lightTheme.fifthFormBg,
            footerBg: colors.lightTheme.footerBg
        },
        text: {
            primary: colors.lightTheme.textPrimary,
            secondary: colors.lightTheme.textSecondary,
            mainTitle: colors.lightTheme.mainTitle,
            secondaryFontFamily: SECONDARY_FONT_FAMILIY,
            thirdStageTitle: colors.lightTheme.thirdStageTitle,
            fourthStageTitle: colors.lightTheme.fourthStageTitle
        },
        action: { active: colors.lightTheme.textPrimary }
    },
    overrides: {
        MuiLink: {
            underlineHover: {
                '&:hover': { color: colors.lightTheme.textHover, textDecoration: 'none' }
            }
        },
        MuiButton: {
            root: {
                padding: '12px 19px',
                fontSize: '1.125rem',
                textTransform: 'capitalize'
            },
            label: { lineHeight: 1 }
        },
        MuiFormLabel: {
            root: {
                '&.Mui-focused': { color: colors.lightTheme.contrastText }
            }
        }
    },
    props: {
        MuiTextField: { variant: 'outlined', size: 'small' },
        MuiButton: {
            color: 'secondary',
            size: 'medium',
            variant: 'contained'
        }
    }
};

const darkTheme: ThemeOptions = {
    ...lightTheme
};

export enum ThemeType {
    LIGHT = 'light',
    DARK = 'dark'
}

export const themes = new Map<ThemeType, ThemeOptions>([
    [ThemeType.LIGHT, lightTheme],
    [ThemeType.DARK, darkTheme]
]);
