import { CircularProgress, Grid, Typography } from '@material-ui/core';
import React from 'react';

interface LoadingScreenProps {
    readonly title?: string;
    readonly subtitle?: string;
    readonly fullWidth?: boolean;
}
export default function LoadingScreen({ title, subtitle, fullWidth }: LoadingScreenProps) {
    return (
        <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
            <Grid item xs={fullWidth ? 12 : 6}>
                <Typography variant="h5">{title}</Typography>
                <Typography variant="body2" align="center">
                    {subtitle}
                </Typography>
            </Grid>
            <Grid item xs={fullWidth ? 12 : 6}>
                <CircularProgress />
            </Grid>
        </Grid>
    );
}
