import { useRef } from 'react';
import { firebaseFunctions } from '../libs/config/FirebaseFunctions';
import { createStore, createStoreContext, DefaultProviderProps } from '../libs/wrappers/StoreWrapp';
import { AirtableApiImpl, IAirtableApi } from './AirtableApi';

export interface ApiInjectorValues {
    readonly airtableApi: IAirtableApi;
}

export const ApiInjector = createStoreContext<ApiInjectorValues>();

export const ApiInjectorProvider = ({ children }: DefaultProviderProps) => {
    const airtableApi = useRef(new AirtableApiImpl(firebaseFunctions));

    return createStore(ApiInjector, children, { airtableApi: airtableApi.current });
};
