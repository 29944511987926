import { EventEmitter } from 'events';
import { PopupProps } from './PopupContainer';

export const CLOSE_TIMEOUT = 4000; // 4 sec

class PopupManager extends EventEmitter {
    private props: PopupProps | undefined;

    public show(props: PopupProps) {
        this.props = props;
        this.emitChange();
        setTimeout(() => this.emit('info', undefined), props.timeout || CLOSE_TIMEOUT);
    }

    remove() {
        this.props = undefined;
        this.emitChange();
    }

    emitChange() {
        this.emit('info', this.props);
    }

    addChangeListener(callback: (props: PopupProps | undefined) => void) {
        this.addListener('info', callback);
    }
}

export default new PopupManager();
