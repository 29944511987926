import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogActionsProps,
    DialogContent,
    DialogContentProps,
    DialogProps,
    Slide,
    SlideProps
} from '@material-ui/core';
import React, { forwardRef, ReactNode, useEffect, useState } from 'react';
import IsMounted from '../../../libs/wrappers/IsMounted';
import { State } from '../../../libs/wrappers/State';
import LoadingButton, { LoadingButtonProps } from '../LoadingButton';
import PopupManager, { CLOSE_TIMEOUT } from './PopupManager';

export interface PopupProps {
    readonly imgSrc?: string;

    readonly title?: ReactNode;
    readonly description?: ReactNode;

    readonly buttonText?: string;
    readonly buttonProps?: LoadingButtonProps;

    readonly timeout?: number;

    readonly dialogProps?: DialogProps;
    readonly contentProps?: DialogContentProps;
    readonly actionProps?: DialogActionsProps;

    readonly onClose?: () => void;
}

const SlideTransition = forwardRef<HTMLDivElement, SlideProps>(function Transition(props, ref) {
    return <Slide direction="up" {...props} ref={ref} />;
});

interface PopupViewProps extends PopupProps {
    readonly open: boolean;
}

function PopupView(props: PopupViewProps) {
    const isMounted = IsMounted();
    const isLoaded = State(false, isMounted);

    useEffect(() => {
        isLoaded.data &&
            !props.buttonText &&
            setTimeout(() => {
                props.onClose && props.onClose();
                isLoaded.set(false);
            }, props.timeout || CLOSE_TIMEOUT);
    }, [isLoaded.data, props.buttonText, props.onClose, props.timeout]);

    return (
        <Dialog
            open={props.open}
            TransitionComponent={SlideTransition}
            {...props.dialogProps}
            style={{
                borderRadius: 6,
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)'
            }}>
            <DialogContent
                style={{
                    maxWidth: 440,
                    minHeight: 200,
                    padding: props.buttonText ? '27px 27px 4px' : 14
                }}
                {...props.contentProps}>
                {props.imgSrc && !isLoaded.data && <CircularProgress size="small" />}
                {props.imgSrc && (
                    <img
                        src={props.imgSrc}
                        style={{ width: '100%', maxWidth: 'fit-content' }}
                        onLoad={() => isLoaded.set(true)}
                    />
                )}
                {props.title}
                {props.description}
            </DialogContent>
            {props.buttonText && (
                <DialogActions
                    style={{ justifyContent: 'center', paddingBottom: 20 }}
                    {...props.actionProps}>
                    <LoadingButton {...props.buttonProps}>{props.buttonText}</LoadingButton>
                </DialogActions>
            )}
        </Dialog>
    );
}

export default function PopupContainer() {
    const [props, setProps] = useState<PopupProps | undefined>();

    useEffect(() => {
        PopupManager.addChangeListener(setProps);
    }, []);

    return <PopupView {...props} open={props !== undefined} />;
}
